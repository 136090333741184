/*jslint browser: true*/
/*global jQuery*/
var Modal = (function($){
    var delConfirm = function (e, params) {
        e.preventDefault();

        var $target = $(e.currentTarget);

        swal($.extend({}, {
            title: 'Are you sure?',
            text: null,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes, delete it!',
            closeOnConfirm: false,
            allowEscapeKey: true,
            allowOutsideClick: true
        }, params),
        function (isConfirm) {
            if (isConfirm) {
                $target.closest('form').submit();
            }
        });
    };

    return {
        'delConfirm': delConfirm
    };
}(jQuery));
