$(function(){
    var pos = 0;

    $('.navbar-toggle').on('click', function(e){
        if($(this).is(':visible')) {
            if ($(this).hasClass('collapsed')) {
                pos = $(document).scrollTop();
                window.scrollTo(0,0);
            } else {
                window.scrollTo(0, pos);
            }
        }
    });

    //jQuery to collapse the navbar on scroll
    $(window).scroll(function() {
        if ($(".navbar").offset().top > 50) {
            $(".navbar-fixed-top").addClass("top-nav-collapse");
        } else {
            $(".navbar-fixed-top").removeClass("top-nav-collapse");
        }
    });

//jQuery for page scrolling feature - requires jQuery Easing plugin
    $('a.page-scroll').bind('click', function(event) {
        var $anchor = $(this);

        if($('.navbar-toggle').is(':visible')) {
            $('.navbar-toggle').trigger('click');
        }

        $('html, body').stop().animate({
            scrollTop: $($anchor.attr('href')).offset().top
        }, 1500, 'easeInOutExpo');
        event.preventDefault();
    });

    $('.navbar-brand').on('click', function(e) {
        e.preventDefault();

        window.scrollTo(0, 0);

        return false;
    });

    $('.navbar').affix({
        offset: {
            top: $('.navbar').offset().top
        }
    });
});

$(document).ready(function() {
    $.ajaxSetup({ headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') } });


    $("[data-mask]").inputmask({
        'definitions': {
            "X": {
                validator: "[0-9A-Za-z\u0410-\u044f\u0401\u0451\xc0-\xff\xb5\u0020őűŐŰ?.!-:;]",
                cardinality: 1,
            }
        },
        'oncomplete': function () {
            $(this).closest('.form-group').removeClass('has-error has-feedback');
        },
        'onincomplete': function () {
            $(this).closest('.form-group').addClass('has-error has-feedback');
        }
    });

    $('form[name="contact"]').on('submit', function(e){
        e.preventDefault();
        var $form = $(this);

        $('input, textarea', $form).each(function(i, data) {
            $(data).focus().blur();
        });


        setTimeout(function() {
            if($('.has-error', $form).length < 1) {
                $('button[type="submit"]', $form).prop('disabled', true);
                $.ajax({
                    method: 'post',
                    url: 'api/contacts',
                    data: $form.serialize()
                    })
                    .done(function (data) {
                        $form[0].reset();
                        swal({
                            title: data.title,
                            text: data.description,
                            type: "success"
                        },
                        function(){
                            $('.confirm', '.sa-button-container').removeAttr('id');
                        });
                        $('.confirm', '.sa-button-container').attr('id', 'confirm-email-sent');
                    })
                    .fail(function( jqXHR, textStatus ) {
                        if (422 == jqXHR.status) {
                            for(var i in jqXHR.responseJSON) {
                                $('[name="'+ i +'"]').addClass('has-error has-feedback');
                            }
                        }
                    })
                    .always(function(){
                        $('button[type="submit"]', $form).prop('disabled', false);
                    });
            }
        }, 1);

        return false;
    });

    $('[data-toggle="tooltip"]').tooltip();
});

